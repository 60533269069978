import { NavigateFunction, useNavigate } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { DocumentNode, useMutation, useQuery } from "@apollo/client";
import { Maybe, ResponsePageInfo, Scalars } from "../../../gql/graphql";
import { DataTable as MantineDataTable } from "mantine-datatable";
import {
  ActionIcon,
  Anchor,
  Button,
  createStyles,
  Group,
  Select,
  Tooltip,
} from "@mantine/core";
import { DataTableColumn } from "mantine-datatable/dist/types/DataTableColumn";
import {
  IconArrowRight,
  IconChevronRight,
  IconCopy,
  IconInfoCircle,
  IconTrash,
} from "@tabler/icons-react";
import { DataTableProps } from "mantine-datatable/dist/types/DataTableProps";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { TadaDocumentNode } from "gql.tada";

const useStyles = createStyles((theme) => ({
  expandIcon: {
    transition: "transform 0.2s ease",
  },
  expandIconRotated: {
    transform: "rotate(90deg)",
  },
}));

export const AutoDataTable = <
  U extends {
    edges: Array<T>;
    nodes: Array<T>;
    pageInfo: ResponsePageInfo;
    totalCount?: Maybe<Scalars["Float"]>;
  },
  T,
  REQUEST extends DocumentNode | TypedDocumentNode<T[] | U>,
>({
  query,
  queryVariables,
  deleteMutation,
  columns,
  itemPath,
  rowExpansion,
  createButtonText,
  linkClick,
  disableNavigation,
  idField = "id" as any,
  ...props
}: Omit<DataTableProps<T>, "columns" | "rowExpansion"> & {
  query: DocumentNode | TypedDocumentNode<T[] | U> | TadaDocumentNode<T[] | U>;
  queryVariables?: Record<string, any>;
  deleteMutation?: DocumentNode;
  createButtonText?: string | false;
  linkClick?: (item: T) => void;
  disableNavigation?: boolean;
  idField?: keyof T;
  columns: {
    title: string;
    accessor: string;
    description?: string;
    rawElement?: (item: T, idx?: number) => ReactNode;
  }[];
  itemPath?: string;
  rowExpansion?: (item: T, idx?: number) => ReactNode;
}) => {
  let navigate: NavigateFunction;
  if (!disableNavigation) {
    navigate = useNavigate();
  }
  const { cx, classes } = useStyles();
  const clipboard = useClipboard();
  const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCountPerPage, setItemCountPerPage] = useState(10);
  const { loading, data, error, refetch } = useQuery(query, {
    fetchPolicy: "network-only",
    variables: {
      count: itemsCountPerPage,
      cursor: null,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  });
  const [mappedColumns, setMappedColumns] = useState<Array<DataTableColumn<T>>>(
    [],
  );
  const [deleteItemMutation] = deleteMutation
    ? useMutation(deleteMutation)
    : [null];

  useEffect(() => {
    setMappedColumns(
      columns
        .map(({ rawElement, description, title, ...column }, rowIdx) => ({
          ...column,
          title: description ? (
            <Group spacing={3}>
              {title}
              <Tooltip label={description}>
                <IconInfoCircle
                  size={16}
                  style={{
                    cursor: "help",
                  }}
                />
              </Tooltip>
            </Group>
          ) : (
            title
          ),
          render:
            (rawElement &&
              ((item: T, idx: number) => rawElement?.(item, idx))) ||
            ((item: T) =>
              rowIdx === 0 &&
              ((rowExpansion && (
                <Group spacing="xs">
                  <IconChevronRight
                    size="0.9em"
                    className={cx(classes.expandIcon, {
                      [classes.expandIconRotated]: expandedRecordIds.includes(
                        item[idField] as any as string,
                      ),
                    })}
                  />
                  <Anchor
                    onClick={() => {
                      if (linkClick) {
                        linkClick(item);
                        return;
                      }
                      if (disableNavigation) return;
                      navigate(
                        (itemPath ? `${itemPath}/` : "") + item[idField],
                      );
                    }}
                  >{`${(item as any)[column.accessor]}`}</Anchor>
                </Group>
              )) || (
                <Group spacing={5}>
                  <Anchor
                    onClick={() => {
                      if (linkClick) {
                        linkClick(item);
                        return;
                      }
                      if (disableNavigation) return;
                      navigate(
                        (itemPath ? `${itemPath}/` : "") + item[idField],
                      );
                    }}
                  >{`${(item as any)[column.accessor]}`}</Anchor>
                  <Tooltip label={"Copy ID to clipboard"}>
                    <ActionIcon
                      size={"xs"}
                      onClick={() => {
                        clipboard.copy(item[idField]);
                        notifications.show({
                          title: "ID copied",
                          message: "ID copied to clipboard",
                        });
                      }}
                    >
                      <IconCopy />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              ))) ||
            undefined,
        }))
        .concat(
          deleteMutation
            ? [
                {
                  accessor: "",
                  title: "",
                  render: (item: T) => (
                    <Tooltip label="Delete item">
                      <ActionIcon
                        color="red"
                        onClick={() =>
                          deleteItem(item[idField] as any as string)
                        }
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  ),
                },
              ]
            : [],
        ),
    );
  }, [columns]);

  const deleteItem = async (id: string) => {
    if (!deleteItemMutation) return;
    if (!confirm("Are you sure you want to delete this element?")) {
      return;
    }
    await deleteItemMutation({
      variables: {
        id,
      },
    });
    await refetch();
  };

  const elemName = (query.definitions[0] as any).selectionSet.selections[0].name
    .value;
  return (
    <>
      {error && "Error: " + error.message}
      {
        <>
          {createButtonText !== false && (
            <Button
              onClick={() => {
                if (disableNavigation) return;
                navigate(itemPath ? `${itemPath}/new` : "new");
              }}
            >
              {createButtonText ?? "New item"}
            </Button>
          )}
          <MantineDataTable
            columns={mappedColumns}
            fetching={loading}
            records={
              (data as any)?.[elemName]?.nodes ?? (data as any)?.[elemName]
            }
            {...(rowExpansion && {
              rowExpansion: {
                allowMultiple: false,
                expanded: {
                  recordIds: expandedRecordIds,
                  onRecordIdsChange: setExpandedRecordIds,
                },
                content: ({ record, recordIndex }) =>
                  rowExpansion(record as T, recordIndex),
              },
            })}
            {...(props as any)}
          />
          {data && (data as any)[elemName]?.totalCount > 0 && (
            <Group position="apart">
              <Group>
                Rows per page{" "}
                <Select
                  data={["10", "20", "50", "100", "200", "500", "1000"]}
                  defaultValue={itemsCountPerPage.toString()}
                  onChange={(value) =>
                    value && setItemCountPerPage(parseInt(value))
                  }
                />
                {(currentPage * itemsCountPerPage).toString()}-
                {(
                  currentPage * itemsCountPerPage +
                  (data as any)[elemName]?.nodes.length
                ).toString()}{" "}
                of {((data as any)[elemName]?.totalCount ?? 0).toString()}
              </Group>
              <div>
                {(data as any)[elemName]?.pageInfo.hasNextPage && (
                  <ActionIcon
                    onClick={() => {
                      refetch({
                        cursor: (data as any)[elemName]?.pageInfo.endCursor,
                        count: itemsCountPerPage,
                        ...queryVariables,
                      }).then(() => setCurrentPage(currentPage + 1));
                    }}
                  >
                    <IconArrowRight />
                  </ActionIcon>
                )}
              </div>
            </Group>
          )}
        </>
      }
    </>
  );
};
