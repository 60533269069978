import { gql } from "@apollo/client";
import { ActionIcon, Anchor, Tooltip } from "@mantine/core";
import { AutoDataTable } from "../../../components/AutoDataTable";
import { graphql } from "gql.tada";
import { IconPlayerPlay } from "@tabler/icons-react";

const PlatformTemplatesQuery = graphql(`
  query platformTemplates {
    platformTemplates {
      id
      name
      description
      public
    }
  }
`);

// TODO Apply modal
// TODO Platform template management and edit

const ApplyTemplateMutation = graphql(`
  mutation platformTemplateApply($platformId: ID!, $platformTemplateId: ID!) {
    platformTemplateApply(
      platformId: $platformId
      platformTemplateId: $platformTemplateId
    ) {
      id
    }
  }
`);

export const PlatformTemplates = () => {
  return (
    <>
      <AutoDataTable
        createButtonText="Add new file"
        deleteMutation={gql`
          mutation ($id: ID!) {
            platformFileDelete(id: $id)
          }
        `}
        query={PlatformTemplatesQuery}
        columns={[
          {
            accessor: "name",
            title: "Name",
          },
          {
            accessor: "description",
            title: "Description",
          },
          {
            accessor: "public",
            title: "Public",
          },
          {
            accessor: "actions",
            title: "Actions",
            rawElement: () => (
              <>
                <Tooltip label={"Apply the template to a platform"}>
                  <ActionIcon
                    title="Apply"
                    variant="transparent"
                    aria-label="Apply"
                  >
                    <IconPlayerPlay />
                  </ActionIcon>
                </Tooltip>
              </>
            ),
          },
        ]}
      />
    </>
  );
};
